import React, { useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import {
  Button, Modal, Form, Header,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import TRANSACTIONS from '../../queries/transaction';
import DataContext from '../../contexts/DataContext';

const ExportTransactionButton = () => {
  const { team } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [fetchTransactions, { loading }] = useLazyQuery(TRANSACTIONS);

  const updateDateRange = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const startDate = moment(dates[0]).startOf('day').valueOf();
      const endDate = moment(dates[1]).endOf('day').valueOf();
      fetchTransactions({
        variables: { teamId: team.id, startDate, endDate },
        onCompleted: ({ transactions }) => {
          setCsvData([
            // Headers
            [
              'Transaction Date',
              'Payment Type',
              'Number of Credits',
              'Expert Name',
              'Project Name',
              'Project Type',
              'Project Code',
            ],
            ...transactions.transactions.map((transaction) => [
              moment(transaction.createdAt).format('l'),
              transaction.type,
              transaction.credits,
              transaction.job?.expert.fullName || 'N/A',
              transaction.job?.project.title || 'N/A',
              transaction.job?.project.type || 'N/A',
              transaction.job?.project.code || 'N/A',
            ]),
          ]);
        },
      });
    }
  };
  const validDateRange = dateRange[0] && dateRange[1];
  return (
    <Modal
      size="mini"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={(
        <Button
          size="small"
          className="basic-btn export-btn"
          content="Export"
        />
      )}
      closeIcon
    >
      <Modal.Header>Select a date range</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            {validDateRange && (
              <Header as="h5">
                {`${moment(dateRange[0]).format('l')} - ${moment(dateRange[1]).format('l')}`}
              </Header>
            )}
            <DatePicker
              inline
              selectsRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              maxDate={new Date()}
              onChange={updateDateRange}
              dateFormat="MMMM d, yyyy"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <CSVLink
          className={`ui primary-btn small button ${!validDateRange || loading ? 'disabled' : ''}`}
          filename={`transactions-${moment(dateRange[0]).format('l')}-${moment(dateRange[1]).format('l')}.csv`}
          data={csvData}
        >
          { loading ? 'Loading data...' : 'Export' }
        </CSVLink>
      </Modal.Actions>
    </Modal>
  );
};

export default ExportTransactionButton;
