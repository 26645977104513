import { gql } from '@apollo/client';

const TRANSACTIONS = gql`
  query Transactions($teamId: ID!, $pagination: Pagination, $startDate: Date, $endDate: Date) {
    transactions(teamId: $teamId, pagination: $pagination, startDate: $startDate, endDate: $endDate) {
      total
      transactions {
        id
        type
        amount
        credits
        description
        createdAt
        team {
          name
        }
        job {
          id
          expert {
            fullName
          }
          project {
            id
            type
            code
            title
          }
        }
      }
    }
  }
`;

export const PAY_EXPERT = gql`
  mutation PayExpert($jobId: ID!, $payment: PaymentInput!) {
    payExpert(jobId: $jobId, payment: $payment) {
      id
      bonuses {
        id
        description
        amount
      }
    }
  }
`;

export default TRANSACTIONS;
